import { GiRodOfAsclepius } from 'react-icons/gi';

const DirectcareBadge = (props) => {
	return(
		<a href="https://directcare.us" className="logoLink">
			<div className="d-flex flex-column jutify-content-center align-items-center" style={{display:'table-cell'}}>
				<div  className="d-flex justify-content-center align-items-center p-2">
					<div className="logoBadge">
						<GiRodOfAsclepius/>
					</div>
					<div className="logoText">
						Directcare
					</div>
				</div>
				<small>return to main site</small>
			</div>
		</a>	
	);
}

export default DirectcareBadge;