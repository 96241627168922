const Department = (props) => {
  if (props.selected === true) {
    return (
      <button
        className="department btn btn-sm btn-primary m-1"
        onClick={() => props.onClick(props.id)}
      >
        {props.name}
      </button>
    );
  } else {
    return (
      <button
        className="department btn btn-sm btn-outline-primary m-1"
        onClick={() => props.onClick(props.id)}
      >
        {props.name}
      </button>
    );
  }
};

export default Department;
