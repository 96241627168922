import { BiLink } from 'react-icons/bi';

const Listing = (props) => {

	const handleListingTeam = ((team) => {
		if(team) {
			return team;
		}else{
			return "Uncategorized";
		}
	})

	return(
		<div className="col-md-6 p-1">
			<div className="card mb-4 shadow-sm">
				<div className="card-body">
					<h5 className="card-title">{props.text}</h5>
					<small class="m-4">{props.cleanString(props.categories.location.split(',')[1])} - {handleListingTeam(props.categories.team)} - {props.categories.commitment}</small>
					<p className="card-text mt-4">
						<blockquote>{props.descriptionPlain.trim().substring(0,400).replace('\n', '') + '...'}</blockquote>
					</p>
					<div className="d-flex justify-content-end align-items-center">
						<a href={props.hostedUrl} type="button" className="btn btn-sm btn-outline-primary"><BiLink/>  View More</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Listing;