import axios from "axios";
import React from "react";
import Department from "./comp/Department";
import Listing from "./comp/Listing";
import ScrollToTopWidget from "./comp/ScrollToTopWidget";
import DirectcareBadge from "./comp/DirectcareBadge";
import { BiCategoryAlt} from "react-icons/bi";
import { ImDisplay } from "react-icons/im";

class App extends React.Component {
  dataUrl = "https://api.lever.co/v0/postings/directcare?group=team&mode=json";

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      initial: [],
      jobs: [],
      departments: [],
      selelectedDepartment: 0,
    };
  }

  filterListings(value) {
    let index = value - 1;
    if (index < 0) {
      this.setState({
        selelectedDepartment: 0,
        jobs: this.state.initial,
      });
    } else {
      let job_array = [];
      for (var j = 0; j < this.state.data[index].postings.length; j++) {
        let temp = this.state.data[index].postings[j];
        job_array.push({ ...temp });
      }

      this.setState({
        jobs: job_array,
        selelectedDepartment: value,
      });
    }
  }

  cleanString(string) {
    if (string) {
      return string;
    } else {
      return "Uncategorized";
    }
  }

  componentDidMount() {
    axios
      .get(this.dataUrl)
      .then((res) => {
        let arr = [];
        arr.push("All");
        res.data.forEach((x) => {
          let temp = this.cleanString(x.title);
          if (!arr.includes(temp)) {
            arr.push(temp);
          }
        });

        let job_array = [];
        for (var i = 0; i < res.data.length; i++) {
          for (var j = 0; j < res.data[i].postings.length; j++) {
            let temp = res.data[i].postings[j];
            job_array.push({ ...temp });
          }
        }

        this.setState({
          data: res.data,
          initial: job_array,
          jobs: job_array,
          departments: arr,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setSelected(index) {
    if (this.state.selelectedDepartment === index) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <div className="container app-container p-0">
        <div className="d-flex p-5 contrast-row justify-content-between align-content-center">
          <h1 className="mt-5 fw-bolder">Available Positions</h1>
          <h1 className="mt-5 d-none d-sm-block"><DirectcareBadge /></h1>
        </div>
        
        <div className="d-flex flex-column p-4 pb-0">
          <h3 className="fw-bold">Teams</h3>
          <small>Select a team to view availble positions.</small>
        </div>
        <div className="d-block-flex p-3">
          {this.state.departments.map((x, index) => {
            return (
              <Department
                key={index + 1}
                selected={this.setSelected(index)}
                id={index}
                name={x}
                onClick={this.filterListings.bind(this)}
              />
            );
          })}
        </div>
        <div className="d-flex contrast-row justify-content-start align-items-center p-4 mt-3">
          <h3 className="fw-bold m-3">Listings</h3>
          <p className="m-3 flex-grow-1 d-none d-sm-block">
            <BiCategoryAlt/> {this.state.departments[this.state.selelectedDepartment]}
          </p>
          <small className="m-3 d-none d-sm-block"><ImDisplay /> &nbsp;&nbsp;{this.state.jobs.length} position{this.state.jobs.length === 1 ? "" : "s"}</small>
        </div>
        <div className="row p-4 pt-3">
          {this.state.jobs.map((x, index) => {
            return (
              <Listing key={index} cleanString={this.cleanString} {...x} />
            );
          })}
        </div>
        <div className="row p-4">
          {this.state.jobs.length === 0 ? (
            <h3 className="fw-bold">No open positions found.</h3>
          ) : null}
        </div>
        <div className="d-flex">
          <ScrollToTopWidget />
        </div>
      </div>
    );
  }
}

export default App;
